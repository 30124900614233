<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home border-bottom-red">
        <div class="container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
                        <img class="icon-red img-left" src="../../assets/images/tata_kelola.png" alt="icon">
                    </div>
                </div>

                <div class="col-10">
                    <div class="content-block mt-3">
                        <h3>Gover<span class="alternate">NANCE</span></h3>

                        <div class="row">
                            <div class="col-lg-3">
                                <img src="../../assets/images/Advanced-Good-Corporate-Governance.jpg" class="img-fluid rounded mb-3" alt="icon">
                            </div>

                            <div class="col-lg-9">
                                <h6 class="text-primary">CORPORATE GOVERNANCE</h6>
                                <p class="text-justify">The Company is committed on an ongoing basis to implement the principles of good corporate governance in accordance with the prevailing laws and regulations in a comprehensive and consistent manner in carrying out its duties and responsibilities.</p>

                                <p class="text-justify">The implementation of good corporate governance becomes a strong foundation to face changes in the business environment in the future. The Company believes that through the implementation of GCG principles on an ongoing basis, it can improve the Company's performance in providing added value for all stakeholders and long-term business sustainability.</p>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-lg-6">
                                <div class="ratio ratio-4x3">
                                    <video class="embed-responsive-item" src="../../assets/video/video1.mp4" controls></video>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="ratio ratio-4x3">
                                    <video class="embed-responsive-item" src="../../assets/video/video2.mp4" controls></video>
                                </div>
                            </div>
                        </div>

                        <template v-for="data in komitmen" :key="data.id">
                            <div v-if="data.id > 0">
                                {{data}}
                            </div>

                            <div v-else>
                                0
                            </div>
                        </template>

                        <template v-for="data in bm" :key="data.id">
                            <div v-if="data.id > 0">
                                <div class="row mt-3">
                                    <div class="col-lg-3">
                                        <img :src="bmPath + data.gambar" class="img-fluid rounded mb-3" alt="icon">
                                    </div>

                                    <div class="col-lg-9">
                                        <h6 class="text-primary"> {{data.judul}} </h6>

                                        <p v-html="data.deskripsi"></p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-for="data in pedoman" :key="data.id">
                            <div v-if="data.id > 0">
                                <div class="row mt-3">
                                    <div class="col-lg-3">
                                        <img :src="pedomanPath + data.gambar" class="img-fluid rounded mb-3" alt="icon">
                                    </div>

                                    <div class="col-lg-9">
                                        <h6 class="text-danger"> {{data.judul}} </h6>

                                        <p v-html="data.pedoman"></p>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="row mt-3">
                            <div class="col-lg-3">
                                <img src="../../assets/images/Advanced-Good-Corporate-Governance.jpg" class="img-fluid rounded mb-3" alt="icon">
                            </div>

                            <div class="col-lg-9">
                                <h6 class="text-primary">RISK MANAGEMENT</h6>
                                <p class="text-justify">In carrying out its business, the Company ensures the process of identifying, monitoring and managing risks, especially potential risks to minimize negative impacts that may be caused to the smooth running of the Company's business to achieve its objectives. To ensure harmony in the application of Risk Management in the Company and as a guideline to raise awareness so that a Corporate Culture will be formed, the General Guidelines for Risk Management of PT. Adhiguna Ark Cruise.</p>
                                <p class="text-justify">This General Guidelines for Risk Management is a continuous follow-up in accordance with the Circular of the Board of Directors No. A.0018/SP.101/DIRUT-2017 dated January 3, 2017 concerning Guidelines for the Implementation of Risk Management in PT Pelayaran Bahtera Adhiguna.</p>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-lg-3">
                                <img src="../../assets/images/Advanced-Good-Corporate-Governance.jpg" class="img-fluid rounded mb-3" alt="icon">
                            </div>

                            <div class="col-lg-9">
                                <h6 class="text-danger">Annual Report</h6>

                                <template v-for="data in laporan" :key="data.id">
                                    <ul>
                                        <li>
                                            <a :href="laporanPath + data.laporan"> {{data.nama_dokumen}} </a>
                                        </li>
                                    </ul>
                                </template>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-lg-3">
                                <img src="../../assets/images/Advanced-Good-Corporate-Governance.jpg" class="img-fluid rounded mb-3" alt="icon">
                            </div>

                            <div class="col-lg-9">
                                <h6 class="text-primary">SHAREHOLDERS INFORMATION</h6>
                                <p class="text-justify">The Company is a Subsidiary of PT PLN (Persero) with 99.9% share ownership and PT PLN (Persero) Welfare Education Foundation (YPK) of 0.1%.</p>
                            </div>
                        </div>

                        <div class="mt-3">
                            <h5 class="text-danger mb-5">Company Achievements</h5>

                            <template v-for="data in pencapaian" :key="data.id">
                                <div class="row mt-3">
                                    <div class="col-lg-3">
                                        <img :src="pencapaianPath + data.gambar" class="img-fluid rounded mb-3" alt="icon">
                                    </div>

                                    <div class="col-lg-9">
                                        <h6 class="text-primary"> {{data.pencapaian}} </h6>

                                        <p v-html="data.deskripsi"></p>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageGovernance',
    components: {
        Banner
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            komitmen: [],
            // komitmenPath: 'http://localhost:8080/assets/komitmen/',
            komitmenPath: 'https://api.plnbag.co.id/assets/komitmen/',
            bm: [],
            // bmPath: 'http://localhost:8080/assets/boardmanual/',
            bmPath: 'https://api.plnbag.co.id/assets/boardmanual/',
            pedoman: [],
            // pedomanPath: 'http://localhost:8080/assets/pedoman/',
            pedomanPath: 'https://api.plnbag.co.id/assets/pedoman/',
            laporan: [],
            // laporanPath: 'http://localhost:8080/assets/laporan/',
            laporanPath: 'https://api.plnbag.co.id/assets/laporan/',
            pencapaian: [],
            // pencapaianPath: 'http://localhost:8080/assets/pencapaian/',
            pencapaianPath: 'https://api.plnbag.co.id/assets/pencapaian/',
        }
    },
    mounted () {
        this.loadBanner()
        this.loadKomitmen()
        this.loadBm()
        this.loadPedoman()
        this.loadLaporan()
        this.loadPencapaian()
    },
    methods: {
        loadBanner () {
            axios.get('banner_tk')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        },

        loadKomitmen () {
            axios.get('commitment_tk')
                .then((res) => {
                    this.komitmen = res.data.data
                })
        },

        loadBm () {
            axios.get('bm_tk_en')
                .then((res) => {
                    this.bm = res.data.data
                })
        },

        loadPedoman () {
            axios.get('guidelines_tk')
                .then((res) => {
                    this.pedoman = res.data.data
                })
        },

        loadLaporan () {
            axios.get('laporan_tk')
                .then((res) => {
                    this.laporan = res.data.data
                })
        },

        loadPencapaian () {
            axios.get('pencapaian_tk')
                .then((res) => {
                    this.pencapaian = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }
</style>